// Search a string for a substring between two strings
// Example: The lazy fox jumps over the brown dog
// searchString('The lazy fox jumps over the brown dog', 'lazy', 'brown') => ' fox jumps over the '
export const searchString = (string, start, end) => {
	const segment = string.split(start);
	if (segment[1]) {
		return segment[1].split(end)[0];
	}
	return '';
};
