// Composables
import { handlePostMeta } from '@/composables/builder/postMeta';

export const handleBuilderUpdates = async (page) => {
    const isPreviewing = useRoute().fullPath.includes('builder.space=');
    if (isPreviewing && process.client) {
        const handleEvent = (event) => {
            const ignoreEvents = ['builder.editingPageMode', 'builder.evaluate', 'builder.configureSdk'];
            if (ignoreEvents.includes(event.data.type)) return;
            switch (event.data.type) {
                case 'builder.contentUpdate':
                    handleContentUpdate(event.data, page);
                    break;
                default:
                    break;
            }
        };
        // Listen for messages from the Builder.io editor
        window.addEventListener('message', (event) => {
            handleEvent(event);
        });
    }
};

const handleContentUpdate = async (event, page) => {
    const { data } = event.data;

    const hasContent = data?.data;

    const hasBlocks = hasContent && data?.data?.blocks;
    // console.log(`🍤 ~ handleContentUpdate ~ hasBlocks:`, hasBlocks);
    if (!hasBlocks) data.data.blocks = [];

    if (hasContent) {
        const updatedPage = { ...page.value.content.data, ...data.data };
        const { fullPath } = useRoute();

        const newPostMeta = await handlePostMeta({ data: updatedPage }, page.value.model, fullPath);
        const postMeta = useState('postMeta', () => newPostMeta);
        postMeta.value = newPostMeta;
        page.value.postMeta = newPostMeta;

        page.value.content.data = updatedPage;
    }
};
