export const fetchBuildingsSameStyle = async (content) => {
	let buildings = [];

	let styleName = '';
	styleName = content?.style;

	let idsArray = [content?.id];
	const fields =
		'id,name,data.buildingName,data.officialName,data.description,data.imageList,data.address,data.neighborhood,data.style,data.architect,data.originalCompletionDate,data.useType,data.fanFavorite,data.encyclopediaLinks,data.url';
	let query = {
		fields: fields,
		'query.id.$nin': [idsArray],
		limit: 8,
	};

	if (styleName?.length) query['query.data.style.$in'] = [styleName];

	const fetchResources = await $fetch('/api/buildings', {
		query: query,
	});

	buildings = fetchResources.data;

	if (fetchResources?.data?.length < 8) {
		fetchResources.data?.forEach((item) => {
			idsArray.push(item?.id);
		});

		if (buildings.length < 8) {
			let secondQuery = {
				fields: fields,
				'query.data.style.$nin': [styleName],
				'query.id.$nin': [idsArray],
				limit: 8 - fetchResources?.data?.length,
			};

			let architects = '';
			if (content?.architect) {
				architects = content?.architect;
				secondQuery['query.data.architect.$in'] = [content?.architect];
			}

			const secondFetchResources = await $fetch('/api/buildings', {
				query: secondQuery,
			});

			if (secondFetchResources?.data?.length) {
				buildings.push(...secondFetchResources.data);
			}

			if (buildings.length < 8) {
				let useType = content?.useType || '';
				secondFetchResources.data?.forEach((item) => {
					idsArray.push(item?.id);
				});
				let thirdQuery = {
					fields: fields,
					'query.data.style.$nin': [styleName],
					'query.data.architect.$nin': [architects],
					'query.id.$nin': [idsArray],
					'query.data.useType.$in': [useType],
					limit: 8 - buildings?.length,
				};
				const thirdFetchResources = await $fetch('/api/buildings', {
					query: thirdQuery,
				});
				if (thirdFetchResources?.data?.length) {
					buildings.push(...thirdFetchResources.data);
				}

				if (buildings.length < 8) {
					thirdFetchResources.data?.forEach((item) => {
						idsArray.push(item?.id);
					});
					let lastQuery = {
						fields: fields,
						'query.data.style.$nin': [styleName],
						'query.data.architect.$nin': [architects],
						'query.id.$nin': [idsArray],
						'query.data.useType.$nin': [useType],
						limit: 8 - buildings?.length,
					};
					const lastResources = await $fetch('/api/buildings', {
						query: lastQuery,
					});
					if (lastResources?.data?.length) {
						buildings.push(...lastResources.data);
					}
				}
			}
		}
	}
	return buildings;
};
