export const fetchBuildingArticles = async (content) => {
    let articles = [];
    if (!content?.data?.encyclopediaLinks?.length) return articles;

    let idsArray = [];
    content.data.encyclopediaLinks.forEach((article, index) => {
        if (article?.link?.id) {
            idsArray.push(article?.link?.id);
        }
    });

    if (!idsArray?.length) return articles;

    const fields = 'data.title,data.image,data.category,data.isPopular,data.url';
    let query = {
        fields: fields,
        'query.id.$in': [idsArray],
    };

    const fetchResources = await $fetch('/api/architecture-encyclopedia-articles', {
        query: query,
    });

    articles = fetchResources.data;
    if (fetchResources?.data?.length < 3) {
        let secondQuery = {
            fields: fields,
            'query.id.$nin': [idsArray],
            limit: (3 - fetchResources?.data?.length),
        };

        const secondFetchResources = await $fetch('/api/architecture-encyclopedia-articles', {
            query: secondQuery,
        });

        if (secondFetchResources?.data?.length) {
            articles.push(secondFetchResources.data);
        }
    }

    return articles;
}