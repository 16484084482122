// Composables
import { getEnv } from '@/composables/helpers/getEnv';
import { handlePostMeta } from '@/composables/builder';
// import { getAllFromModel } from '@/composables/builder/getAllFromModel';

export const getPageContent = async (route, model = 'page', noPostMeta = false) => {
 const builderApi = getEnv('builderApi');
 const builderEnv = getEnv('builderEnv');
 const models = getEnv('models', true);
 const localRoutes = getEnv('localRoutes', true);
 const cacheDuration = getEnv('cacheDuration', true);
 const now = new Date();
 const cacheBuster = route.includes('cache=off');

 if (route.includes('#')) route = route.split('#')[0];

 if (route.startsWith('/iframe')) route = route.replace('/iframe', '');

 // Remove trailing slash
 let cleanURL = route.split('?')[0];
 // Also remove any hashes that would break the query.
 cleanURL = cleanURL.split('#')[0];
 if (cleanURL.endsWith('/')) cleanURL = cleanURL.slice(0, -1);
 if (cleanURL === '') cleanURL = '/';

 // If the route is a local route, abort
 if (localRoutes.length > 0) {
  const matches = localRoutes.filter((localRoute) => route.startsWith(localRoute));
  if (matches.length > 0) {
   return { abort: true };
  }
 }

 // Find model by route
 const currentModel = await getModelFromSlug(route);
 if (currentModel !== model) model = currentModel;

 // Fetch page content options
 let cacheSeconds = 1; // Dev
 let includeUnpublished = true;
 if (builderEnv === 'Production') {
  cacheSeconds = cacheDuration; // Production
  includeUnpublished = false;
 }

 let isPreview = false;
 if (route.includes('showUnpublished=true') || route.includes('builder.space=')) {
  includeUnpublished = true;
  isPreview = true;
 }

 if (cacheBuster) {
  cacheSeconds = 1;
 }

 // Allow Builder to know they are in preview mode
 let path = cleanURL;
 if (route.includes('builder.space=')) {
  path = route;
 }

 // Replace paths on models that have multiple paths
 // Should only be exhibitions since they archive
 for (const key in models) {
  const modelPaths = models[key];
  if (typeof modelPaths === 'string') continue;
  modelPaths.forEach((modelPath, index) => {
   if (index === 0) return;
   if (route.includes(modelPath)) {
    path = path.replace(modelPath, modelPaths[0]);
   }
  });
 }

 // Here we can add more user attributes for advanced targeting in Builder.io
 const userAttributes = {
  urlPath: path,
 };

 let query = {
  data: {
   environment: builderEnv,
  },
 };

 //  We don't need this, since City Tours supports environments now.
 //  Get all models that should not have environment models
 //  const no_environment_models = getEnv('no_environment_models', true);
 //  if (no_environment_models.includes(model)) {
 //   // Remove environment from query
 //   delete query.data.environment;

 //   // If the data key is empty, remove it
 //   if (Object.keys(query.data).length === 0) {
 //    delete query.data;
 //   }

 //   // If the query is empty, remove it
 //   if (Object.keys(query).length === 0) {
 //    query = null;
 //   }
 //  }

 // Convert to query strings
 const convertObjectToQueryString = (obj, nestedKeys = '') => {
  if (!obj) return '';

  const keys = Object.keys(obj);
  if (keys.length === 0) return '';

  let strings = [];

  for (const key of keys) {
   if (typeof obj[key] === 'object') {
    const deeplyNestedStrings = convertObjectToQueryString(obj[key], nestedKeys + key + '.');
    strings = [...strings, ...deeplyNestedStrings];
   } else {
    const value = `${nestedKeys + key}__||__${obj[key]}`;
    strings.push(value);
   }
  }

  return strings;
 };

 const queryString = convertObjectToQueryString(query, 'query.');

 let showNow = null;
 if (route.includes('builder.space=') || route.includes('cache=off')) {
  showNow = now.toISOString();
 }

 const params = {
  apiKey: builderApi,
  userAttributes,
  includeUnpublished: true,
  cacheSeconds,
  showNow,
  'builder.cacheBust': cacheBuster,
  'query.$or': [{ endDate: { $gte: now } }, { endDate: { $exists: false } }, { endDate: { $eq: null } }],
 };

 queryString.forEach((string) => {
  const split = string.split('__||__');
  params[split[0]] = split[1];
 });

 const BuilderPageAPIResponse = await $fetch(`https://cdn.builder.io/api/v3/content/${model}`, {
  method: 'GET',
  params,
 });

 // TODO: Get the correct result
 // console.log(`🍤 ~ getPageContent ~ BuilderPageAPIResponse.results:`, BuilderPageAPIResponse.results);

 // If start date is in the future remove it
 // TODO: Find a better way to filter moving forward
 const filteredResults = filterBuilderResponse(BuilderPageAPIResponse.results, model, route, isPreview);

 // If there are more than one result, sort the one with the newest start date
 const sortedResults = sortBuilderResponse(filteredResults);

 // Get the first result
 const content = addBlockIndex(sortedResults[0]);

 if (noPostMeta) {
  return {
   content,
   api: builderApi,
   model,
  };
 }

 const postMeta = await handlePostMeta(content, model, route);

 return {
  content,
  api: builderApi,
  model,
  postMeta,
 };
};

const getAllFromModel = async (model, API, environment = false) => {
 const results = [];
 let keepGoing = true;

 while (keepGoing) {
  const params = {
   apiKey: API,
   limit: 100,
   offset: results.length,
   includeUnpublished: true,
   cacheSeconds: 1,
   'builder.cachebust': 'true',
   'builder.noCache': 'true',
  };

  if (environment) params['query.data.environment'] = environment;

  const entries = await $fetch(`https://cdn.builder.io/api/v3/content/${model}`, {
   params,
  });

  if (entries.results.length <= 0) keepGoing = false;
  else results.push(...entries.results);
 }

 return results;
};

export const getModelFromSlug = async (route) => {
 // Remove trailing slash on parent routes / clean up the route
 route = route.split('/?')[0];
 route = route.split('/#')[0];
 route = route.split('?')[0];
 route = route.split('#')[0];

 console.log(`🍤 ~ getModelFromSlug ~ route:`, route);
 if (!route) return 'page';
 if (route.endsWith('/')) route = route.slice(0, -1);

 const localRoutes = getEnv('localRoutes', true);

 // If the route is a local route, abort
 if (localRoutes.length > 0) {
  const matches = localRoutes.filter((localRoute) => route.startsWith(localRoute));
  if (matches.length > 0) {
   return { abort: true };
  }
 }

 const models = getEnv('models', true);
 // Find model by route
 const baseModel =
  Object.keys(models).find((key) => {
   const isString = typeof models[key] === 'string';
   if (isString) return route.includes(models[key]);
   else {
    return models[key].some((model) => route.includes(model));
   }
  }) || 'page';

 // Augment the "models" object with the reserved-url-exceptions
 let whiteListModel = null;

 const modelsString = getEnv('modelsString', true);
 const rawWhiteList = await getAllFromModel('reserved-url-exceptions', getEnv('builderApi'));

 let whiteList = [];
 if (rawWhiteList.length <= 0) {
  console.error('Error fetching reserved-url-exceptions');
  return;
 } else {
  whiteList = rawWhiteList.map((entry) => {
   const { data } = entry;
   if (modelsString[data.model]) data.model = modelsString[data.model];
   else {
    console.error(`Model not found: ${data.model}`);
    console.log(`Full Data: `, data);
   }
   return data;
  });
 }

 for (let i = 0; i < whiteList.length; i++) {
  const { path, model } = whiteList[i];
  const cleanRoute = route.split('?')[0];

  const splitRoute = cleanRoute.split('/');
  const splitPath = path.split('/').filter((item) => item !== '*');

  let match = false;
  if (path == cleanRoute) {
   match = 1000;
  } else if (splitRoute.length !== splitPath.length) {
   const nestedPathSelector = splitPath.join('/') + '/';
   match = cleanRoute.includes(nestedPathSelector);
  }

  if (match) {
   whiteListModel = model;
   break;
  }
 }

 const model = whiteListModel || baseModel;

 return model;
};

const filterBuilderResponse = (results, model, route, isPreview = false) => {
 return results.filter((item) => {
  if (!isPreview) return item.published == 'published';

  if (item.startDate) return item.startDate <= now;

  if (model === 'exhibitions' && item.name != 'dummy') {
   const isArchived = route.includes('/online-resources/archived-exhibitions/');
   const itemIsArchived = item.data.isArchived || false;
   return itemIsArchived == isArchived;
  }

  return true;
 });
};

const sortBuilderResponse = (results) => {
 return results.sort((a, b) => {
  // -1 if a should come before b
  // 1 if b should come before a
  // 0 if they are the same
  let sortWeight = 0;

  // Sort by start date
  const startDateA = a.startDate || false; // 1718889589
  const startDateB = b.startDate || false; // 1715954247

  // If both have start dates
  if (startDateA && startDateB) {
   const startDateComparison = startDateB - startDateA; // 1715954247 - 1718889589 = -2935342
   sortWeight = minMax(startDateComparison, -1, 1); // -1
   // Amplify the weight of the start date comparison by 2
   sortWeight = sortWeight * 2; // -2
  }

  // Sort by lastUpdated
  const lastUpdatedA = a.lastUpdated || 0; // 1721224647
  const lastUpdatedB = b.lastUpdated || 0; // 1723903047

  const lastUpdatedComparison = lastUpdatedB - lastUpdatedA; // 1723903047 - 1721224647 = 2678400
  sortWeight = sortWeight + minMax(lastUpdatedComparison, -1, 1); // -2 + 1 = -1

  // Negative sort weight means a should come before b
  return minMax(sortWeight, -1, 1); // -1
 });
};

const minMax = (value, min, max) => {
 if (value < min) return min;
 if (value > max) return max;
 return value;
};

const addBlockIndex = (content) => {
 const hasBlocks = content?.data?.blocks && content?.data?.blocks.length > 0;
 if (!hasBlocks) return content;

 content.data.blocks = content.data.blocks.map((block, index) => {
  const hasOptions = block.component?.options;
  if (!hasOptions) {
   block.component = { ...block.component, options: block?.component?.options || {} };
   block.component.options = { ...block.component.options, blockIndex: index };
   return block;
  }

  block.component.options = { ...block.component.options, blockIndex: index };
  return block;
 });

 return content;
};
