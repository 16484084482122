// Composables
import { searchString } from '@/composables/helpers/strings';

import { fetchBuildingArticles } from '~/composables/helpers/fetchBuildingArticles';
import { fetchBuildingsSameStyle } from '../helpers/fetchBuildingsSameStyle';
import { getPageContent } from '@/composables/builder/api.js';

export const handlePostMeta = async (content, model, route) => {
  if (content === undefined) return;
 content.model = model;
 switch (model) {
  case 'buildings':
   return await handleBuildings(content, route, model);
  case 'team-members':
   return await handleTeamMembers(content, route);
  case 'tours':
   return content;
  case 'programs-events':
   return await handleProgramEvent(content, route, model);
  default:
   return content;
 }
};

async function handleBuildings(content, route, model) {
 const frozen = JSON.parse(JSON.stringify(content?.data));
 let clone = JSON.parse(JSON.stringify(content?.data));
 clone.model = model;
 let id = content?.id;
 if (id == 'dummy' || id == undefined) {
  const model = searchString(route, 'builder.preview=', '&');
  const routeId = searchString(route, `builder.overrides.${model}=`, '&');
  id = routeId;
 }

 frozen.id = id;

 clone.id = id;

 const articles = await fetchBuildingArticles(frozen);
 clone.fetchedArticles = articles.flat();

 const buildingsWithSameStyle = await fetchBuildingsSameStyle(frozen);
 clone.fetchedBuildingsWithSameStyle = buildingsWithSameStyle.flat();

 return clone;
}

async function handleProgramEvent(content, route, model) {
 const frozen = JSON.parse(JSON.stringify(content?.data));
 let clone = JSON.parse(JSON.stringify(content?.data));
 clone.model = model;
 let id = content?.id;
 if (id == 'dummy') {
  const model = searchString(route, 'builder.preview=', '&');
  const routeId = searchString(route, `builder.overrides.${model}=`, '&');
  id = routeId;
  const cleanRoute = route.split('?')[0];
  const pageData = await getPageContent(cleanRoute, model, true);
  clone.force = pageData.content;
 }

 frozen.id = id;

 clone.id = id;

 return clone;
}

async function handleTeamMembers(content, route) {
 let clone = JSON.parse(JSON.stringify(content?.data));

 let id = content?.id;
 if (id == 'dummy') {
  const model = searchString(route, 'builder.preview=', '&');
  const routeId = searchString(route, `builder.overrides.${model}=`, '&');
  id = routeId;
 }

 clone.id = id;

 return clone;
}
