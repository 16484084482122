import { schemaString } from '@/composables/helpers/dates';

export const updateSeo = (page) => {
 const seo = page?.content?.data?.seo?.seo || false;
 let options = getSeoOptions(page);

 if (seo.removeTitleTail) options.titles = { template: options.title };

 // Remove HTML from options
 options = JSON.stringify(options);
 const removeHTML = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
 options = options.replace(removeHTML, '');
 options = JSON.parse(options);
 useSeo(options);

 if (seo.noIndex) {
  addSeo({ meta: [{ name: 'robots', content: 'noindex' }] });
 }
};

export const getSeoOptions = (page, isHit) => {
 let seo = page?.content?.data?.seo?.seo || false;
 if (isHit) seo = page?.content?.data?.seo || false;

 const title = getTitle(seo, page);
 const description = getDescription(seo, page);
 const image = getImage(seo, page);

 const options = {
  title,
  description,
  webpage: {
   datePublished: schemaString(page?.content?.firstPublished) || false,
   dateModified: schemaString(page?.content?.lastUpdated) || false,
   image,
  },
  schema: [
   {
    hid: 'breadcrumbs',
    schema: page.breadcrumbs,
   },
  ],
 };

 return options;
};

const getTitle = (seo, page) => {
 if (seo?.title && seo.title.trim() != '') return seo.title;

 // Page Title
 let title = page?.content?.name || page?.content?.data?.title;

 switch (page?.model || '') {
  case 'buildings':
   title = page?.content?.data?.buildingName || title;
   title = page?.content?.data?.officialName || title;
   break;
  case 'team-members':
   const name = page?.content?.data?.name;
   const jobTitle = page?.content?.data?.title;

   let newTitle = title;
   if (name) newTitle = name;
   if (name && jobTitle) newTitle = `${name} - ${jobTitle}`;
   title = newTitle;
   break;
 }

 // Fail Safe, get Builder Page Name
 if (!title) title = page?.content?.data?.name;

 return title;
};

const getDescription = (seo, page) => {
 if (seo?.description && seo.description.trim() != '') return seo.description;

 let description = page?.content?.data?.description || page?.content?.data?.shortDescription;

 switch (page?.model || '') {
  case 'team-members':
   description = page?.content?.data?.bio || description;
   break;
  case 'tours':
   // Only update with Blurb if there's no description
   if (!description) description = page?.content?.data?.blurb;
   break;
 }
 return description;
};

const getImage = (seo, page) => {
 if (seo?.shareImage && seo.shareImage.trim() != '') return seo.shareImage;

 let image = '/social.jpg';

 switch (page?.model || '') {
  case 'architecture-encyclopedia-articles':
   image = page?.content?.data?.image || image;
   image = page?.content?.data?.imageList?.[0]?.src || image;
   break;

  case 'buildings':
   image = page?.content?.data?.imageList?.[0]?.src || image;
   break;
  case 'exhibitions':
   image = page?.content?.data?.cardOnlyImage || image;
   image = page?.content?.data?.featuredImages?.[0]?.image || image;
   break;
  case 'programs-events':
   image = page?.content?.data?.imageList?.[0]?.image || image;
   break;
  case 'resources':
   image = page?.content?.data?.image || image;
   break;
  case 'team-members':
   image = page?.content?.data?.image || image;
   break;
  case 'stories':
   image = page?.content?.data?.image?.src || image;
   break;
  case 'tours':
   image = page?.content?.data?.gallery?.[0]?.image || image;
   break;
 }
 return image;
};
